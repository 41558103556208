<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class='wrap'>
    <mt-header title="付款说明">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="invoice-pay-explain">
      <div class="item-wrap">
        <ImagePreview imgUrl="https://xiyk.oss-cn-hangzhou.aliyuncs.com/qrcode.png" alt="pay" class="pay" />
      </div>
      <div class="item-wrap">
        <div class="item-line">
          <span>待支付手续费</span>
          <span class="pay-amt">{{`¥ ${detail.handlingFee || 0}`}}</span>
        </div>
        <div class="item-line" :style="{ flexWrap: 'wrap' }">
          <span :style="{ width: '100%' }">请将下列内容复制/粘贴到付款备注中</span>
          <span :style="{ width: '100%', border: '1px solid #8e8e8e', textAlign: 'center', borderRadius: '2.6667vw', margin: '2vw 0' }">{{`个体户${detail.applyUserName}开票申请${detail.amt}元`}}</span>
          <div
            class="copy"
            v-clipboard:copy="`个体户${detail.applyUserName}开票申请${detail.amt}元`"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >复制</div>
        </div>
        
      </div>
      <div class="confrim-btn" @click="handleConfirm">确认</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'mint-ui';
import ImagePreview from '@components/image-preview';
export default {
  name: "invoice-pay-explain",
  props: ["info"],
  components: {
    ImagePreview
  },
  data() {
    return {
      detail: {}
    };
  },
  mounted() {
    if (!this.info) {
      this.$router.back(-1);
    } else {
      this.detail = JSON.parse(decodeURIComponent(this.info));
    }
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    onCopy: function (e) {
      Toast('复制成功');
    },
    onError: function (e) {
      Toast('复制失败, 请手动长按复制');
    },
    handleConfirm() {
      this.$router.replace('/individual-business/invoice-apply');
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background-color: #f0f2f5;
}
.invoice-pay-explain {
  padding: 2.9333vw 5.3333vw 14.5333vw;
}
.item-wrap {
  width: 100%;
  padding: 8vw 5.3333vw;
  background-color: #ffffff;
  border-radius: 3.2vw;
  box-shadow: 0.4vw 0.4vw 1vw 0.43vw rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 3.2vw;
}
.item-line {
  font-size: 4vw;
  color: rgba(51,51,51,0.7);
  & + .item-line  {
    margin-top: 6vw;
  }
  display: flex;
  > span {
    display: inline-block;
    &:nth-of-type(1) {
      white-space: nowrap;
      // width: 33%;
      text-align: left;
      margin-right: 3%;
      color: rgba(51,51,51, 1);
      display: flex;
      align-items: center;
    }
    &:nth-of-type(2) {
      width: 64%;
      font-weight: 500;
    }
  }
  .pay-amt {
    font-size: 6.6667vw !important;
    font-weight: 500 !important;
    color: rgba(193,39,45,1) !important;
  }
}
.confrim-btn {
  font-size: 4.5333vw;
  font-weight: bold;
  color: rgba(255,255,255,1);
  height: 9.3333vw;
  line-height: 9.3333vw;
  width: 100%;
  background-image: linear-gradient(to bottom right, #2ba2f9, #47e2f8);
  text-align: center;
  border-radius: 4.5333vw;
  margin-top: 5.3333vw;
}
.pay {
  width: 100%;
  height: 100%;
  object-fit: object-fit;
}
.copy {
  width: 16.8vw;
  height: 10.6667vw;
  line-height: 10.6667vw;
  border: 1px solid #e4e4e4;
  font-size: 4.5333vw;
  font-weight: 500;
  color: rgba(51,51,51,1);
  text-align: center;
  margin: 0 auto;
}
</style>